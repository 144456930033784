import React from "react"
import ErickClaveria from "../components/icons/ErickClaveria"

/**
 * About section
 * This section is used to show an about section with
 * - first column
 *   - Title and quote
 * - second column
 *   - Text
 *   - Signature
 *   - Signer
 *
 * @param {Object} props
 */
const AboutSection = ({ title, quote, children, signature, signer }) => {
  return (
    <section className="about-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <h2 className="mb-4">{title}</h2>
            <p>
              La oficina del licenciado Erick Estuardo Claveria Roldan fue
              fundada en el año 2016 en la ciudad de San Rafael California, al
              día de hoy tiene el privilegio de servirle a todos los
              guatemaltecos que residen en San Francisco California, a lo largo
              del área de la bahía y la unión americana, esta compuesta por un
              grupo de abogados y notarios guatemaltecos de alto nivel y
              profesionalismo.
            </p>
          </div>
          <div className="col-lg-6 about-section__content">
            <div className="content">{children}</div>
            <div className="simple__content">
              <ErickClaveria className="about-section__signature" />
              <span className="signer">{signer}</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutSection
