import React from "react"
import { Link } from "gatsby"
import { FaLongArrowAltRight } from "react-icons/fa"
import OptimizedImage from "gatsby-image/withIEPolyfill"
import CourthouseIcon from "./icons/CourthouseIcon"
import JusticeIcon from "./icons/JusticeIcon"
import LawyerIcon from "./icons/LawyerIcon"
import JudgeIcon from "./icons/JudgeIcon"
import ProtectIcon from "./icons/ProtectIcon"
import PolicyIcon from "./icons/PolicyIcon"

/**
 * Services Component
 * This section is generating the blue version of the services section
 *
 * @param {Object} props
 */
const Services = ({ image, alt }) => {
  return (
    <section className="services-section">
      <div className="container">
        <div className="row services-section__header">
          <div className="col-lg-5 col-md-8">
            <h2>Servicios Legales para Guatemaltecos en San Francisco, California.</h2>
            <blockquote>
            Área de la bahia y la unión americana.
            </blockquote>
          </div>
          <div className="col-lg-4 col-md-8">
            <Link className="btn btn-red" to="/servicios">
              <span>Ver mas servicios</span>
              <FaLongArrowAltRight className="right" />
            </Link>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-4 col-md-12">
            <OptimizedImage
              className="services__image"
              fluid={image}
              alt={alt || "Services image"}
              fadeIn={true}
              objectFit="contain"
              objectPosition="50% 50%"
            />
          </div>
          <div className="col-lg-8 col-md-12">
            <div className="row">
              <div className="col-md-6 services-section__box">
                <div>
                  <CourthouseIcon className="box__icon" />
                </div>
                <div>
                  <h3>Mandatos</h3>
                  <p>
                La ley de Guatemala clasifica los Mandatos en Generales, Especiales
                y Judiciales, de esa cuenta existen varios tipos de mandatos que
                dependiendo del negocio o acto jurídico que usted necesite...
                  </p>
                  <Link className="link" to="/servicios/mandatos">
                    <span>Leer más...</span>
                    <FaLongArrowAltRight className="right" />
                  </Link>
                </div>
              </div>

              <div className="col-md-6 services-section__box">
                <div>
                  <JusticeIcon className="box__icon" />
                </div>
                <div>
                  <h3>Compra / Venta de Propiedades</h3>
                  <p>
                  Estimado guatemalteco te brindamos asesoría legal cuando desees
                  vender una propiedad en Guatemala, para realizar dicha venta
                  de manera rápida y segura...
                  </p>
                  <Link className="link" to="/servicios/compra-venta-de-propiedades">
                    <span>Leer más...</span>
                    <FaLongArrowAltRight className="right" />
                  </Link>
                </div>
              </div>

              <div className="col-md-6 services-section__box">
                <div>
                  <LawyerIcon className="box__icon" />
                </div>
                <div>
                  <h3>Testamentarios o Intestados</h3>
                  <p>
                  La sucesión por causa de muerte puede darse de dos formas,
                  la primera por voluntad de la persona manifestada a través
                  de un testamento en cual dispone a que persona se le hereda los bienes...
                  </p>
                  <Link className="link" to="/servicios/testamentarios-o-intestados">
                    <span>Leer más...</span>
                    <FaLongArrowAltRight className="right" />
                  </Link>
                </div>
              </div>

              <div className="col-md-6 services-section__box">
                <div>
                  <JudgeIcon className="box__icon" />
                </div>
                <div>
                  <h3>Divorcios en Guatemala</h3>
                  <p>
                  El fundamento legal lo encontramos en el libro I
                  artículo  154 del Código Civil de Guatemala. La ley señala
                  dos formas para obtener su divorcio...
                  </p>
                  <Link className="link" to="/servicios/divorcios">
                    <span>Leer más...</span>
                    <FaLongArrowAltRight className="right" />
                  </Link>
                </div>
              </div>

              <div className="col-md-6 services-section__box">
                <div>
                  <ProtectIcon className="box__icon" />
                </div>
                <div>
                  <h3>Testamentos</h3>
                  <p>
                El testamento es un acto puramente personal y de carácter revocable...
                  </p>
                  <Link className="link" to="/servicios/testamentos">
                    <span>Leer más...</span>
                    <FaLongArrowAltRight className="right" />
                  </Link>
                </div>
              </div>

              <div className="col-md-6 services-section__box">
                <div>
                  <PolicyIcon className="box__icon" />
                </div>
                <div>
                  <h3>Reconocimiento de Hijos</h3>
                  <p>
                  El licenciado Erick Claveria le puede ayudar a resolver el
                  reconocimiento voluntario de su hijo (a) y el Código civil
                  de Guatemala establece dos formas...
                  </p>
                  <Link className="link" to="/servicios/reconocimiento-de-hijos/">
                    <span>Leer más...</span>
                    <FaLongArrowAltRight className="right" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Services
