import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import AboutSection from "../components/aboutSection"
import Services from "../components/services"
// import Team from "../components/team"
import ContactForm from "../components/contactForm"

/**
 * GraphQL Query to retrieve page data
 *
 * @return {Object}
 */
export const pageQuery = graphql`
  {
    site {
      siteMetadata {
        email
        phone
      }
    }
    hero: file(relativePath: { eq: "pages/index/hero-sf.jpg" }) {
      sharp: childImageSharp {
        fluid(
          quality: 100
          maxWidth: 1920
          duotone: { highlight: "#282d3a", shadow: "#282d3a", opacity: 55 }
          ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    services: file(relativePath: { eq: "pages/index/services.jpg" }) {
      sharp: childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

/**
 * Index Page Component
 * This component is used to generate the Main page of the site
 * This page can be accessible using the path `/`
 *
 * @param {Object} props
 */
const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Home" />

      <Hero
        type="main"
        background={data.hero.sharp.fluid}
        tagline="Graduado de la Universidad de San Carlos"
        title="Lic. Erick Claveria"
        description="Servicios Legales para Guatemaltecos en Estados Unidos"
        cta={{ title: "Contactame", link: "/" }}
      />

      <AboutSection
        title="Abogado y Notario de Guatemala en Estados Unidos"
        quote="Proident culpa officia deserunt mollit animd laborum perspicia natus error voluptatem qui acusantium dolore laudan."
        signer="Fundador & Licenciado"
      >
        <p>
          El licenciado Erick Estuardo Claveria Roldan es Abogado y Notario
          Guatemalteco graduado en la Universidad de San Carlos De Guatemala en
          el año 2007, colegiado activo del Colegio de Abogados y Notarios de
          Guatemala y debidamente registrado en El Archivo General de Protocolos
          del Organismo Judicial de la Republica de Guatemala y en el Registro
          General de La Propiedad de Guatemala.
        </p>
        <p>
          Nuestro objetivo primordial es brindarle asesoría jurídica de las
          leyes guatemaltecas a todos los chapines que residen en Estados Unidos
          de América, darles una solución real y efectiva en el menor tiempo
          posible a todos sus asuntos legales pendientes de resolver en
          Guatemala, la elaboración de mandatos y otros documentos legales como
          escrituras públicas y actas notariales, que son elaborados llenando
          estrictamente cada uno de los requisitos de conformidad con las leyes
          Guatemaltecas, dándoles a todos aquellos guatemaltecos que residen en
          San Francisco California, a lo largo del área de la bahía y la unión
          americana que deseen utilizar nuestros servicios la certeza que su
          asunto legal será resuelto con profesionalismo altamente calificado y
          en forma pronta y cumplida.
        </p>
      </AboutSection>

      <Services image={data.services.sharp.fluid} />

      <ContactForm
        email={data.site.siteMetadata.email}
        phone={data.site.siteMetadata.phone}
      />

      {/*<Team />*/}
    </Layout>
  )
}

export default IndexPage
